<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div class="share">分享二维码</div>
          <vue-qr
            class="erweima"
            :text="hrefs + '?' + phone"
            :dotScale="0.5"
            :size="160"
            :margin="5"
          ></vue-qr>
          <div class="share_copy">
            复制图片发送给您的朋友，注册后您可获得相应奖励
            <router-link to="/vipCenter/friendsList">邀请好友列表</router-link>
          </div>
          <div class="share_flex">
            <div class="share_a">分享链接</div>
            <input
              type="text"
              class="share_inp"
              readonly
              :value="hrefs+'?'+phone"
              style="padding-left: 5px"
            />
            <button
              class="copy"
              v-clipboard:copy="hrefs+'?'+phone"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
              style="cursor:pointer"
            >
              复制链接
            </button>
          </div>
          <div class="invitation">如何邀请好友</div>
          <div class="invitation_xiang">
            <p>1.截屏或保存二维码并发送给好友</p>
            <p>2.好友扫码或识别二维码注册汇成国际账户</p>
            <p>3.您即可获得最高500积分奖励，积分可兑换5元现金红包。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@network/setting";
export default {
  name: "Friends",
  data() {
    return {
      hrefs: "https://www.hcgj.co/static/register.html",
      // hrefs: "https://hcgj.craftsman-web.com.cn/static/register.html",
      phone:''
    };
  },
  created() {
    this.getUserInfo();
  },

  components: {},

  methods: {
    copySuccess() {
      this.$message.success({message:"复制成功",offset: 300});
    },
    copyError() {
      this.$message.success({message:"复制失败",offset: 300});
    },
    getUserInfo() {
      getUserInfo().then((res) => {
        console.log(res);
        if (res.code == 403) {
          this.$storage.remove("token");
          this.$message.error({
            message: "请重新登录",
            offset: 300
          });
          this.$router.replace("/home");
          return false;
        }
        this.phone = window.btoa(res.msg.phone);
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../../assets/css/vipCenter/ChildComps/friends.less";
</style>
